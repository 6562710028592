<template>
  <container-list-extend title="Visite" class="d-flex flex-column w-100">
    <template v-slot:title>
      <div class="w-100 pl-2">Visite du {{ $moment(visite.visite_date).format("DD/MM/YYYY") }}</div>
    </template>
    <template v-slot:form>
      <div>
        <base-form deleteBtn :loading="loading" @click="updateVisites(visite)" @delete="deleteVisites(visite)">
          <base-form-row row>
            <select-collaborateur
              class="mb-3"
              v-model.number="visite.visite_pilote_id"
              :text="'Pilote'"
            ></select-collaborateur>
            <base-inputDatePicker
              v-model="visite.visite_date"
              inputText="Date de visite"
              :errors="feedback.visite_date"
            ></base-inputDatePicker>
          </base-form-row>
          <base-form-row row>
            <base-select
              inputText="Société"
              v-model.number="visite.visite_societe_id"
              :options="data.societes"
              :errors="feedback.visite_societe_id"
              :disabled="false"
            ></base-select>
            <base-select
              inputText="Affaire"
              v-model.number="visite.visite_affaire_id"
              :options="affaires"
              :errors="feedback.visite_affaire_id"
              :disabled="false"
            ></base-select>
          </base-form-row>
          <base-form-row row>
            <base-input-area
              v-model="visite.visite_synthese"
              text="Synthèse"
              :errors="feedback.visite_synthese"
            ></base-input-area>
          </base-form-row>
          <base-form-row row>
            <base-select
              inputText="Site client"
              v-model.number="visite.visite_site_id"
              :options="data.sites"
              :errors="feedback.visite_site_id"
            ></base-select>
          </base-form-row>
          <div class="w-100 mt-4">
            <uploader
              v-if="visite.id"
              id="document_affaire_suivi"
              url="/upload/document"
              title="Pièces jointes"
              :file_system="'CRM'"
              :file_field="'suivi_' + visite.id"
              :file_key="visite.visite_affaire_id"
            ></uploader>
          </div>
        </base-form>
      </div>
    </template>
    <template v-slot:actions>
      <actions-linked v-if="tab === 'actions'" :origine_system="21" :origine_id="visite.id"></actions-linked>
    </template>
  </container-list-extend>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { errHandler } from "@/helpers.js";
import ContainerListExtend from "@/components/containers/ContainerListExtend.vue";
import BaseForm from "@/components/bases/Form.vue";
import BaseFormRow from "@/components/bases/FormRow.vue";
import BaseInputDatePicker from "@/components/bases/InputDatePicker.vue";
import BaseInputArea from "@/components/bases/InputArea.vue";
// import BaseInput from "@/components/bases/Input.vue";
import BaseSelect from "@/components/bases/Select.vue";
import Uploader from "@/components/bases/Uploader.vue";
import actionsLinked from "@/components/qsser/actionsLinked.vue";
// import menuItem from "@/components/qsser/menuItem.vue";
import SelectCollaborateur from "@/components/bases/SelectCollaborateur.vue";
// import Checkbox from "@/components/bases/Checkbox.vue";
// import BaseIcon from "@/components/bases/Icon.vue";

export default {
  components: {
    ContainerListExtend,
    BaseForm,
    BaseFormRow,
    BaseInputDatePicker,
    BaseInputArea,
    // BaseInput,
    BaseSelect,
    actionsLinked,
    Uploader,
    SelectCollaborateur,
  },
  data() {
    return {
      old: false,
      loading: false,
      feedback: {},
      tab: "infos",
    };
  },
  mounted() {},
  methods: {
    ...mapActions({
      _updateVisites: "qsser/updateVisites",
      _deleteVisites: "qsser/deleteVisites",
    }),
    updateVisites: function (visite) {
      this.feedback = {};
      this.loading = true;
      this._updateVisites(visite)
        .catch((error) => (this.feedback = errHandler(error)))
        .finally(() => (this.loading = false));
    },
    deleteVisites: function (visite) {
      if (!window.confirm("Confirmer la suppression de l'élément ?")) return null;
      this.feedback = {};
      this.loading = true;
      this._deleteVisites(visite)
        .catch((error) => (this.feedback = errHandler(error)))
        .finally(() => (this.loading = false));
    },
  },
  props: {
    visite: {
      Type: Object,
      Requierd: true,
      Default: {},
    },
    actions: {
      Type: Object,
      Requierd: true,
      Default: {},
    },
  },
  computed: {
    ...mapGetters({
      data: "qsser/data",
      affaires: "affaires/affaires",
    }),
  },
};
</script>
<style></style>
